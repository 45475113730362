import { BaseService } from './_BaseService';

export class AuthorizationService extends BaseService {

    static Permissions = {
        ACCESS_REFERRAL_PROGRAM: "ACCESS_REFERRAL_PROGRAM",
        ACCESS_SALES_DASHBOARD: "ACCESS_SALES_DASHBOARD",
        ACCESS_SALES_PIPELINE: "ACCESS_SALES_PIPELINE"
    }

    constructor(){
        super();
        const loggedInUserProp = localStorage.getItem(this.LOCAL_STORAGE_LOGGED_IN_USER_PROP) || "";
        this.loggedInUser = loggedInUserProp === "" && loggedInUserProp !== "undefined" ? null : JSON.parse(loggedInUserProp);
    }

    can(permission){
        return (this.loggedInUser.permissions || []).indexOf(permission) >= 0;
    }

    setLoggedInUser(_loggedInUser){
        this.loggedInUser = _loggedInUser;
        localStorage.setItem(this.LOCAL_STORAGE_LOGGED_IN_USER_PROP, JSON.stringify(_loggedInUser));
    }

    isLoggedIn(){
        return this.loggedInUser ? true : false;
    }

    getCurrentUserId = () => {
        return this.loggedInUser.id;
    }

    checkEmail = async (name, email) => {
        return await this.post(`sales/check-email`, { name, email });
    }

    login = async (username, password) => {
        return await this.post(`sales/login`, { username, password });
    }

    logout = async () => {
        this.loggedInUser = null;
        localStorage.removeItem(this.LOCAL_STORAGE_LOGGED_IN_USER_PROP);
        localStorage.removeItem(this.LOCAL_STORAGE_AUTHORIZATION_TOKEN_PROP);
    }

    sendToken = async (email) => {
        return await this.post(`sales/send-reset-token`, { email });
    }

    checkToken = async (token) => {
        return await this.post(`sales/check-reset-token`, { token });
    }

    resetPassword = async (password) => {
        return await this.post(`sales/reset-password`, { password });
    }
}
import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { ProgressBar } from 'primereact/progressbar';

import * as _ from 'lodash';
import * as uuid from 'uuid';

import { ReferralService } from '../services/ReferralService';


export function ReferralsForm(props) {

    let history = useHistory();

    const referralTemplate = {
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        status: "",
        type: ""
    }

    const [submitted, setSubmitted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [referrals, setReferrals] = useState(props.user.referrals || []);
    const [created, setCreated] = useState(false);
    const [activeTab, setactiveTab] = useState("REFERRALS");
    
    useEffect(async () => {
        
        let _referrals = [];
        while(_referrals.length < 1){
            _referrals = _referrals.concat([Object.assign({id: uuid.v4()}, referralTemplate)]);
        }
        setReferrals(_referrals);
    }, []);

    const add = () => {
        setReferrals([].concat(referrals).concat([Object.assign({id: uuid.v4(), new: true}, referralTemplate)]));
    }

    const isUsed = (referral) => {
        return referral.first_name !== "" || referral.last_name !== "" || referral.mobile !== "" || referral.email !== "";
    }

    const isValidEmail = (email) => 
    {
        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(email))
        {
            return (true);
        }
        return (false)
    }
    
    const isDataValid = () => {
        
        
        for(let i = 0; i < referrals.length; i++){
            if(isUsed(referrals[i])){
                if(referrals[i].first_name === "" || referrals[i].last_name === "" || referrals[i].mobile === "" || (referrals[i].email !== "" & !isValidEmail(referrals[i].email))){
                    return false;
                }
    
                if(referrals.find(r => r.mobile !== "" && cleanPhone(r.mobile) === cleanPhone(referrals[i].mobile) && r.id !== referrals[i].id)){
                    return false;
                }
    
                if(referrals.find(r => r.email !== "" && r.email === referrals[i].email && r.id !== referrals[i].id)){
                    return false;
                }
            }
        }

       
        return true;
    }

    const save = async () => {
        try 
        {
            setSubmitted(true);
            if(isDataValid()){
                setSaving(true);
                const service = new ReferralService();
                await service.batchSave(props.user, referrals, true);
                setCreated(true);
                setSaving(false);
            }
            else {
                console.log("Data is not valid")
            }
            
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            if(error.code && error.code === 400){
                setReferrals(error.message.referrals);
            }
            else {
                alert(error);
            }            
        }
    }

    const updateValue = (referral, fieldName, value) => {
        const _referrals  = [].concat(referrals);
        _.set(_referrals.find(r => r.id === referral.id), fieldName, value);
        setReferrals(_referrals);
    }

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    return <>
    
        <div className='left-panel form'>

            <div className='title'>ADD REFERRALS</div>
            <div className='desc'>Add your referrals by using the form below.</div>
            <div className='tabs-container'>
                
                <div className='tabs'>
                    <div className='tabs-header'>
                        <div className='tabs-spacer'>&nbsp;</div>
                        <div className={`tab${ activeTab === "REFERRALS" ? ' active' : ''}`} onClick={() => { setactiveTab('REFERRALS'); window.scrollTo(0, 0) }}>ADD REFERRALS</div>
                        <div className='tab-spacer'></div>
                        <div className='tabs-spacer'>&nbsp;</div>
                    </div>
                    <div className='tabs-content-container'>
                        <div className={`tabs-content${ activeTab === "REFERRALS" ? ' first' : ' second'}`}>
                            <div className={`tab-content${ activeTab === "REFERRALS" ? ' active' : ''}`}>
                                
                                { created && 
                                    <>
                                        <div className='submit-return'>
                                            <div className="message">Submit complete!</div>
                                            <div className="buttons">    
                                                <Button label="Submit More" className="button" onClick={() => history.push("/")} />
                                            </div>
                                        </div>
                                    </>
                                }
                                { !created &&
                                <div className="referrals">
                                    { 
                                        (referrals || []).map((referral,i) => 
                                        <> 
                                            <div className="referral" key={`referral_${i}`}>
                                                <div className="field">
                                                    <InputText value={referral.first_name || ""} onChange={(e) => updateValue(referral, 'first_name', e.target.value)} placeholder="First Name" />
                                                    { submitted && isUsed(referral) && referral.first_name === "" && <div className="referral-error">First name is mandatory</div>}
                                                </div>
                                                <div className="field">
                                                    <InputText value={referral.last_name || ""} onChange={(e) => updateValue(referral, 'last_name', e.target.value)} placeholder="Last Name"/>
                                                    { submitted && isUsed(referral) && referral.last_name === "" && <div className="referral-error">Last name is mandatory</div>}
                                                </div>
                                                <div className="field">
                                                    <InputMask autoClear={false} mask="(999) 999-9999" value={referral.mobile || ""} placeholder="Mobile Phone" unmask={true} onChange={(e) => updateValue(referral, 'mobile', cleanPhone(e.value))}></InputMask>
                                                    { (referral.error || "") === "PHONE_ALREADY_REFERRED" && <div className="referral-error">Mobile number was already referred</div>}
                                                    { submitted && isUsed(referral) && referral.mobile === "" && <div className="referral-error">Mobile is mandatory</div>}
                                                    { isUsed(referral) && referrals.find(r => r.mobile !== "" && cleanPhone(r.mobile) === cleanPhone(referral.mobile) && r.id !== referral.id) && <div className="referral-error">Duplicate mobile number</div> }
                                                </div>
                                                <div className="field">
                                                    <InputText value={referral.email || ""} onChange={(e) => updateValue(referral, 'email', e.target.value)} placeholder="Email Address" />
                                                    { (referral.error || "") === "EMAIL_ALREADY_REFERRED" && <div className="referral-error">Email was already referred</div>}
                                                    { submitted && isUsed(referral) && referral.email !== "" && !isValidEmail(referral.email) && <div className="referral-error">Email is invalid</div>}
                                                    { isUsed(referral) && referrals.find(r => r.email !== "" && r.email === referral.email && r.id !== referral.id) && <div className="referral-error">Duplicate email address</div> }
                                                </div>
                                            </div>
                                        </>)
                                    }
                                    <div className="buttons" style={{ display: 'flex', justifyContent:'space-between'}}>
                                        <Button disabled={saving} label="SUBMIT         →" className="button" onClick={save} />     
                                        <Button disabled={saving} label="+" className="button" onClick={add} />   
                                        
                                    </div>
                                    { saving && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                                </div>
                                }
                            </div>
                        </div>
                        <div className='tabs-content-spacer'>&nbsp;</div>
                    </div>
                    <div className='desc'>→ Any questions? Please contact <a href="mailto: pvillareal@poweredbyelevation.com" target="_new">Priscilla Villareal</a></div>
                </div>
                
            </div>
            
        </div>
        
    </>
}
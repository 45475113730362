import React, { useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

export function Login(props) {

    const login = async () => 
    {
        // window.analytics.identify(user.email, {
        //     name: user.name,
        //     email: user.email,
        // });
        
        props.onAuthenticated(Object.assign(team, { product, installers }));
    }

    const [team, setTeam] = useState(null);
    const teams = [
        { name: 'EE/Curb Team', code: 'EECURB', email: "eecurb.installers@poweredbyelevation.com" },
        { name: 'Solar - Ops Team', code: 'SOLAR', email: "solar.installers@poweredbyelevation.com" }
    ];

    const [installers, setInstallers] = useState(null);
    const installersList = [
        { name: 'Adam Mentier', email: 'amentier@poweredbyelevation.com', code: 'amentier@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Adam Puente', email: 'apuente@poweredbyelevation.com', code: 'apuente@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Adam Zepeda', email: 'azepeda@poweredbyelevation.com', code: 'azepeda@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Alan Moreno', email: 'amoreno@poweredbyelevation.com', code: 'amoreno@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Alexander Warwick', email: 'awarwick@poweredbyelevation.com', code: 'awarwick@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Alfonso Luna', email: 'aluna@poweredbyelevation.com', code: 'aluna@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Andrew Macdonald', email: 'amacdonald@poweredbyelevation.com', code: 'amacdonald@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Andrew McQueen', email: 'amcqueen@poweredbyelevation.com', code: 'amcqueen@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Angel Rondan', email: 'arondan@poweredbyelevation.com', code: 'arondan@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Anthony Duenas-Seym', email: 'aduenas@poweredbyelevation.com', code: 'aduenas@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Anthony Hernandez', email: 'ahernandez@poweredbyelevation.com', code: 'ahernandez@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Armbra McMillion', email: 'amcmillion@poweredbyelevation.com', code: 'amcmillion@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Bryce Cwiklinski', email: 'bcwiklinski@poweredbyelevation.com', code: 'bcwiklinski@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Carlos Hernandez Flores', email: 'chernandez@poweredbyelevation.com', code: 'chernandez@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Dalton Spencer', email: 'dspencer@poweredbyelevation.com', code: 'dspencer@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Daniel Facio', email: 'dfacio@poweredbyelevation.com', code: 'dfacio@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Danilo Retotal', email: 'dretotal@poweredbyelevation.com', code: 'dretotal@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'David Knapp', email: 'dknapp@poweredbyelevation.com', code: 'dknapp@poweredbyelevation.com', team: 'EECURB' },
        { name: 'David Urrutia', email: 'durrutia@poweredbyelevation.com', code: 'durrutia@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Dustin Stapley', email: 'dstapley@poweredbyelevation.com', code: 'dstapley@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Dwyane Baker', email: 'dbaker@poweredbyelevation.com', code: 'dbaker@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Edward Martinez', email: 'emartinez@poweredbyelevation.com', code: 'emartinez@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Emily Albrecht', email: 'ealbrecht@poweredbyelevation.com', code: 'ealbrecht@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Jaime Lopez', email: 'jalopez@poweredbyelevation.com', code: 'jalopez@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Jakalas Mitchell jr', email: 'jmitchell@poweredbyelevation.com', code: 'jmitchell@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'James Hern', email: 'jhern@poweredbyelevation.com', code: 'jhern@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Javier Lucero', email: 'jlucero@poweredbyelevation.com', code: 'jlucero@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Jeremy Barnes', email: 'jbarnes@poweredbyelevation.com', code: 'jbarnes@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Johnnie Richardson', email: 'jrichardson@poweredbyelevation.com', code: 'jrichardson@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Jose L Vega Nunez', email: 'jvega@poweredbyelevation.com', code: 'jvega@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Juan Gonzalez', email: 'jugonzalez@poweredbyelevation.com', code: 'jugonzalez@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Kenneth Jones', email: 'kjones@poweredbyelevation.com', code: 'kjones@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Kristopher Cooper', email: 'kcooper@poweredbyelevation.com', code: 'kcooper@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Lance Breaux', email: 'lbreaux@poweredbyelevation.com', code: 'lbreaux@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Lawrence Richardson III', email: 'lrichardson@poweredbyelevation.com', code: 'lrichardson@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Loren Richards', email: 'lrichards@poweredbyelevation.com', code: 'lrichards@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Marilyn Field', email: 'mfield@poweredbyelevation.com', code: 'mfield@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Mark Smith', email: 'masmith@poweredbyelevation.com', code: 'masmith@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Matthew Wisniewski', email: 'mwisniewski@poweredbyelevation.com', code: 'mwisniewski@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Michael Ganiron', email: 'mganiron@poweredbyelevation.com', code: 'mganiron@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Nathaniel Garcia', email: 'ngarcia@poweredbyelevation.com', code: 'ngarcia@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Paul Menard', email: 'pmenard@poweredbyelevation.com', code: 'pmenard@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Pedro Rondan', email: 'prondan@poweredbyelevation.com', code: 'prondan@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Pete Sosa', email: 'psosa@poweredbyelevation.com', code: 'psosa@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Rene Sanchez', email: 'resanchez@poweredbyelevation.com', code: 'resanchez@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Ricardo Briseno Morales', email: 'rimorales@poweredbyelevation.com', code: 'rimorales@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Richard Cardenas', email: 'rcardenas@poweredbyelevation.com', code: 'rcardenas@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Rigo Diaz', email: 'rdiaz@poweredbyelevation.com', code: 'rdiaz@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Rob  Jones', email: 'rjones@poweredbyelevation.com', code: 'rjones@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Robert Rumbaugh', email: 'rrumbaugh@poweredbyelevation.com', code: 'rrumbaugh@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Rolando Moreno', email: 'rmoreno@poweredbyelevation.com', code: 'rmoreno@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Roman Reyes', email: 'rreyes@poweredbyelevation.com', code: 'rreyes@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Ronnie Brown', email: 'rbrown@poweredbyelevation.com', code: 'rbrown@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Salvador Ortega', email: 'sortega@poweredbyelevation.com', code: 'sortega@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Sam Esekia', email: 'sesekai@poweredbyelevation.com', code: 'sesekai@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Samuel Hernandez Chavez', email: 'shernandez@poweredbyelevation.com', code: 'shernandez@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Schuyler Williams III', email: 'swilliams@poweredbyelevation.com', code: 'swilliams@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Skylar Larson', email: 'slarson@poweredbyelevation.com', code: 'slarson@poweredbyelevation.com', team: 'EECURB' },
        { name: 'Thomas Kelley, llll', email: 'tkelley@poweredbyelevation.com', code: 'tkelley@poweredbyelevation.com', team: 'EECURB' },
        { name: 'William Wollenzier', email: 'bwollenzier@poweredbyelevation.com', code: 'bwollenzier@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Zachary Malay', email: 'zmalay@poweredbyelevation.com', code: 'zmalay@poweredbyelevation.com', team: 'SOLAR' },
        { name: 'Eduardo Varella', email: 'evarella@gmail.com', code: 'evarella@gmail.com', team: 'SOLAR' },
    ];

    const [product, setProduct] = useState(null);
    const products = [
        { name: 'Solar', code: 'solar' },
        { name: 'Energy Efficiency', code: 'ee' },
        { name: 'Curb', code: 'curb' }
    ];

    const getForm = () => {
        return <>
        <div className="form-box">
            <div className='fields'>
                <div className="formfield">
                    <Dropdown value={team} onChange={(e) => { setTeam(e.value); setInstallers(null);}} options={teams} optionLabel="name" placeholder='Select a Team' />
                </div>
                <div className="formfield">
                    <MultiSelect value={installers} onChange={(e) => setInstallers(e.value)} options={installersList.filter(i => i.team === (team || {}).code)} optionLabel="name" placeholder="Select Team Members"/>
                </div>
                <div className="formfield">
                    <Dropdown value={product} onChange={(e) => setProduct(e.value)} options={products} optionLabel="name" placeholder='Select a Product' />
                </div>
            </div>
            <div className="buttons">
                <div className='button login' onClick={login}>
                    <div>Continue</div>
                    <div>→</div>
                </div>
            </div>
        </div>
        </>;
    }

    return <div className="screen login">
            <div className="login-left-panel">
                <div className='logo'>
                    <img id="logo" alt="Elevation Solar" src="assets/layout/images/logo.png"/>
                </div>
                <div className='title'>Welcome to<br/>Elevation<br/>Installers Portal</div>
                <div className='sub-title'>For Elevation Installation Team</div>
                <div className="login-right-panel mobile">
                    {getForm()}
                </div>
            </div>
            <div className="login-right-panel desktop">
                {getForm()}
            </div>
    </div>
}